<script setup>
import { defineProps, ref, toRaw, watch } from "vue";

import { defineExpose } from "vue";
import InputComponent from "./InputComponent.vue";
import SelectComponent from "./SelectComponent.vue";
import TextAreaComponent from "./TextAreaComponent.vue";

// Define props
const props = defineProps({
  form: {
    type: Object,
    required: true,
  },
});

const images = ref([]);

// Track whether this accordion is open or closed
const isAccordionOpen = ref(false);

const toggleAccordion = () => {
  isAccordionOpen.value = !isAccordionOpen.value;
};

const getFormField = (field) => {

  let fileName = null;
  if (
    field?.componentName === "Single Media" ||
    field?.componentName === "Multiple Media"
  ) {
    if (field?.value?.length) {
      let result;
      if (images?.value) {
        result = images.value.reduce((acc, [id, obj]) => {
          if (!acc[id]) {
            acc[id] = 0;
          }

          if (field?.componentName === "Single Media") {
            acc[id] = 1;
          } else {
            if(obj)
              acc[id] += Object.keys(obj).length;
          }

          return acc;
        }, {});
      }

      if (field?.files?.length) {
        fileName =
          field.files.length > 1
            ? `${field.files.length + result[field.id]} files`
            : field.value[0].name;
      } else {
        fileName =
          field.componentName === "Multiple Media"
            ? `${result[field.id]} files`
            : field.value[0].name;
      }
    } else if (field?.files?.length) {
      fileName =
        field.files.length > 1
          ? `${field.files.length} files`
          : field.files[0].name;
    }
  }
  const baseProps = {
    id: field?.id,
    label: field?.label,
    required: field?.isRequired,
    modelValue: field?.value,
    minLength: field?.minimumLength,
    maxLength: field?.maximumLength,
    defaultValue: field?.defaultValue,
    placeholder: field?.placeholder,
    pattern: field?.regExp,
    options: field?.options,
    allowMultiple: field?.allowMultiple,
    showComponentItself: field?.showComponentItself,
    fileName: fileName,
    format: field?.format,
  };
  //  console.log("field: " + field);
  switch (field.componentName) {
    case "Text":
    case "Short Text":
      return {
        component: InputComponent,
        props: {
          ...baseProps,
          type: "text",
        },
      };
    case "Long Text":
      return {
        component: InputComponent,
        props: {
          ...baseProps,
          type: "textarea",
        },
      };
    case "Email":
      return {
        component: InputComponent,
        props: {
          ...baseProps,
          type: "email",
        },
      };
    case "Password":
      return {
        component: InputComponent,
        props: {
          ...baseProps,
          type: "password",
        },
      };
    case "Number":
      return {
        component: InputComponent,
        props: {
          ...baseProps,
          type: "number",
        },
      };
    case "Rich Text":
      return {
        component: InputComponent,
        props: {
          ...baseProps,
          type: "richtext",
        },
      };
    case "Telephone":
      return {
        component: InputComponent,
        props: {
          ...baseProps,
          type: "tel",
        },
      };
    case "Radio":
      return {
        component: InputComponent,
        props: {
          ...baseProps,
          type: "radio",
        },
      };
    case "Range":
      return {
        component: InputComponent,
        props: {
          ...baseProps,
          type: "range",
        },
      };
    case "Checkbox":
      return {
        component: InputComponent,
        props: {
          ...baseProps,
          type: "checkbox",
        },
      };
    case "Date":
      return {
        component: InputComponent,
        props: {
          ...baseProps,
          type: "date",
        },
      };
    case "Datetime":
      return {
        component: InputComponent,
        props: {
          ...baseProps,
          type: "datetime-local",
        },
      };
    case "Week":
      return {
        component: InputComponent,
        props: {
          ...baseProps,
          type: "week",
        },
      };
    case "Month":
      return {
        component: InputComponent,
        props: {
          ...baseProps,
          type: "month",
        },
      };
    case "Time":
      return {
        component: InputComponent,
        props: {
          ...baseProps,
          type: "time",
        },
      };
    case "Link":
      return {
        component: InputComponent,
        props: {
          ...baseProps,
          type: "url",
        },
      };
    case "Single Media":
      return {
        component: InputComponent,
        props: {
          ...baseProps,
          type: "file",
        },
      };
    case "Boolean":
      return {
        component: InputComponent,
        props: {
          ...baseProps,
          type: "checkbox",
        },
      };
    case "Multiple Media":
      return {
        component: InputComponent,
        props: {
          ...baseProps,
          type: "file",
          multiple2: true,
        },
      };

    case "Image":
      return {
        component: InputComponent,
        props: {
          ...baseProps,
          type: "image",
        },
      };
    case "Enumeration":
      return {
        component: SelectComponent,
        props: {
          ...baseProps,
          options: field.options || [],
        },
      };
    case "DropDown":
      return {
        component: TextAreaComponent,
        props: {
          ...baseProps,
          options: field.options || [],
        },
      };
    case "CheckBox":
      return {
        component: TextAreaComponent,
        props: {
          ...baseProps,
          options: field.options || [],
        },
      };
    case "RadioButton":
      return {
        component: TextAreaComponent,
        props: {
          ...baseProps,
          options: field.options || [],
        },
      };
    default:
      return null;
  }
};

watch(images.value, (newImages, oldImages) => {
  console.log('Images array updated:', newImages);
  // Additional logic can be added here
});

const returnImagesData = () => {
  const data = toRaw(images.value);
  console.log("data-----",data);
  return data;
};

defineExpose({ returnImagesData });
</script>

<template>
  <div class="accordion" :id="`accordion-${props.form.id}`">
    <div class="accordion-item">
      <h2 class="accordion-header">
        <button
          class="accordion-button"
          type="button"
          :class="{ collapsed: !isAccordionOpen }"
          @click="toggleAccordion"
          :aria-expanded="isAccordionOpen"
          :aria-controls="`collapse-${props.form.id}`"
        >
          <b>{{ props.form.label }}</b>
        </button>
      </h2>
      <div
        :id="`collapse-${props.form.id}`"
        class="accordion-collapse collapse"
        :class="{ show: isAccordionOpen }"
        v-if="props.form.childComponents.length"
      >
        <div class="accordion-body">
          <div class="colorclass">
            <template
              v-for="(item, index) in props.form.childComponents"
              :key="index"
            >
              <template v-if="item.componentName !== 'Components'">
                <component
                  v-if="item.componentName !== 'Collections'"
                  :is="getFormField(item)?.component"
                  v-bind="getFormField(item)?.props"
                  @update:modelValue="value => { if(getFormField(item)?.props.type === 'file'){ item.value=value; images.push([item.id, value]); } else { item.value = value} }" 
                  @update:options="value=>item.options=value"
                />
                <div
                  v-else,
                  class="collection_item"
                  title="You can add collection items from Content Manager > Collections"
                >
                  <label>
                    <p>Collection : {{ item.label }}</p>
                  </label>
                </div>
              </template>
              <template v-else>
                <ContentManagerComponent :form="item" />
              </template>
            </template>
            <!-- <component
              v-for="(field, index) in props.form.childComponents"
              :key="index"
              :is="getFormField(field)?.component"
              v-bind="getFormField(field)?.props"
              @update:modelValue="(value) => (field.value = value)"
              @update:options="(value) => (field.options = value)"
            /> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.accordion-item {
  margin: 2px;
}

.labelstyle {
  margin: 2vh 0vh 0vh 0vh;
}

.accordion-button {
  width: 100%;
  color: #0146f4 !important;
  margin: 0;
}

.accordion-button:focus {
  box-shadow: none;
}

/* .accordion-button:not(.collapsed) {
  background-color: white;
} */

.inputstyle {
  width: 100%;
}

.colorclass {
  color: #0146f4;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.borderedContainer2 {
  padding: 5px;
  border-radius: 5px;
  border: 1px solid #d9d9d9;
  color: #0146f4;
}

.form-preview-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: left;
  gap: 0rem;
}

input {
  width: auto !important;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;
  color: black;
  padding: 10px;
}

input::placeholder {
  color: #999;
}

.accordion {
  width: 98%;
}

.collection_item {
  width: 98%;
  border: 1px solid #dee2e6;
  border-radius: 5px;
  padding: 16px 20px;
  cursor: pointer;

  p {
    font-size: 1rem;
  }
}
</style>
